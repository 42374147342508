.App {
  text-align: center;
}
img {
  max-width: 100%;
}
nav {
  background-color: #313131;
}
nav ul {
  margin:0px;padding: 0;
}
nav ul li {
  display: inline-block;
  list-style-type: none;
  margin:10px;
}
nav ul li a {
  color: #fff;
}
ul.archiveOpere {
  padding: 0;
  overflow: auto;
}
ul.archiveOpere li {
  float: left;
  list-style-type: none;
  text-align: left;    padding: 10px;
}
ul.archiveOpere li img {
  height: 200px;
}
p.pagination {
  float: left;
  width: 100%;
}

.singleOpera img {
  width: 600px;
  max-width: 100%;
}